<template>
  <div>
    <ul class="gallery">
      <li v-for="(item, index) in pageData.list" :key="index">
        <video :src="item.melPath" lazy v-if="item.melType == 'video'" @click="displayResource(item)"/>
        <!--        <img :src="item.melPath" v-else-if="item.melType == 'img'"/>-->
        <el-image :src="item.melPath" lazy v-else-if="item.melType == 'img'" :preview-src-list="imgList"/>
      </li>
    </ul>

    <el-pagination style="margin-top: 15px"
                   background
                   @current-change="handleCurrentChange"
                   :pager-count="5"
                   :page-size="pageData.pageSize"
                   layout="prev, pager, next"
                   :total="pageData.total">
    </el-pagination>

    <el-dialog
        :visible.sync="dialogVisible"
        :destroy-on-close=true
        width="90%"
        @opened="opened"
        :modal-append-to-body="false"
        :append-to-body="true"
        :before-close="handleClose">
      <div style="">
        <ArtPlayer ref="mediaXgPlayer"/>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import mediaLibrary from "@/request/expand/mediaLibrary";
import ArtPlayer from "../../../components/Artplayer.vue";

export default {
  components: {
    ArtPlayer,
  },
  name: "media_Library",
  data() {
    return {
      ask: new mediaLibrary(),
      resList: [],
      dialogVisible: false,
      imgList: [],
      videoMel: null,
      player: null,
      pageData: {'list': []}
    }
  },
  mounted() {
    this.getMediaLibraryAll(1, 20);
  },
  methods: {
    handleCurrentChange(val) {
      this.getMediaLibraryAll(val, 20);
    },
    opened() {
      this.$refs.mediaXgPlayer.changeUrl(this.videoMel['melPath']);
    },
    handleClose(done) {
      this.$confirm('确认关闭')
          .then(_ => {
            done();
            // this.player.dispose();
          })
          .catch(_ => {
          });
    },
    /**
     * 显示资源
     */
    displayResource(item) {
      this.dialogVisible = true;
      this.videoMel = item;
    },

    /**
     * 查询资源
     * @param number
     * @param size
     */
    getMediaLibraryAll(number, size) {
      this.ask.mediaLibraryAll({
        "pageNum": number,
        "pageSize": size
      }).then(res => {
        res.list.forEach(item => {
          if (item.melType == 'img') {
            this.imgList.push(item.melPath)
          }
        })
        this.pageData = res
      });
    }
  }
}
</script>

<style scoped>
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 150px 200px;
  gap: 1rem;
}

li:nth-child(2) {
  grid-row: span 2;
}

li:nth-child(4) {
  grid-row: span 3;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  padding: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' viewBox='0 0 700 700' width='700' height='700'%3E%3Cdefs%3E%3ClinearGradient gradientTransform='rotate(230, 0.5, 0.5)' x1='50%25' y1='0%25' x2='50%25' y2='100%25' id='ffflux-gradient'%3E%3Cstop stop-color='%23FD6C13' stop-opacity='1' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23E63E00' stop-opacity='1' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3Cfilter id='ffflux-filter' x='-20%25' y='-20%25' width='140%25' height='140%25' filterUnits='objectBoundingBox' primitiveUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.001 0.001' numOctaves='2' seed='221' stitchTiles='stitch' x='0%25' y='0%25' width='100%25' height='100%25' result='turbulence'%3E%3C/feTurbulence%3E%3CfeGaussianBlur stdDeviation='0 0' x='0%25' y='0%25' width='100%25' height='100%25' in='turbulence' edgeMode='duplicate' result='blur'%3E%3C/feGaussianBlur%3E%3CfeBlend mode='color-dodge' x='0%25' y='0%25' width='100%25' height='100%25' in='SourceGraphic' in2='blur' result='blend'%3E%3C/feBlend%3E%3C/filter%3E%3C/defs%3E%3Crect width='700' height='700' fill='url(%23ffflux-gradient)' filter='url(%23ffflux-filter)'%3E%3C/rect%3E%3C/svg%3E");
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  min-width: 0;
  padding: 14px;
  background-color: #fff;
  border-radius: 5px;
  border: #2c3e50 1px solid;
  /*box-shadow: 0.3px 0.5px 0.7px hsl(0deg 93% 31% / 0.36),*/
  /*0.8px 1.6px 2px -0.8px hsl(23deg 93% 31% / 0.36),*/
  /*2.1px 4.1px 5.2px -1.7px hsl(23deg 93% 31% / 0.36),*/
  /*5px 10px 12.6px -2.5px hsl(23deg 93% 31% / 0.36);*/
}

img, video, .el-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#charonVideo {
  display: block;
  width: 100%;
  /*height: 400px;*/
  height: 100%;
}

button {
  display: block;
  width: fit-content;
  padding: 8px 16px;
  border: 0;
  border-radius: 3px;
  margin-block-start: 1rem;
  background-color: #fff;
  color: #222;
  font: 14px/1.6 system-ui;
}

/deep/ .el-dialog__body {
  padding: 5px;
}
</style>